import React, { useContext, useState } from 'react';

import { MenuIcon, XIcon } from '@heroicons/react/solid';
import classnames from 'classnames';
import Cookies from 'js-cookie';
import Image from 'next/image';
import { usePostHog } from 'posthog-js/react';
import { Navbar, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { LinkButton } from '_ui/_common/Button';
import { MainNavLink } from '_ui/_navigation/MainDynamicNavigation/constants';
import { UserDropdown } from '_ui/_navigation/UserDropdown';
import { InternationalizationContext } from 'components/_context/InternationalizationContext';
import { RouterContext } from 'components/_context/RouterContext';
import NavLink from 'components/_nav/NavLink';
import ChangeLanguageButton from 'components/ChangeLanguageButton';
import Link from 'components/Link';
import SearchBar from 'components/SearchBar';
import dunsguideLogo from 'public/images/dunsguide_logo.png';
import { api } from 'store/api';
import { useUser } from 'store/hooks';
import { replaceDirection } from 'utils/directionFormat';
import { getCompanyPath } from 'utils/routes';

import CookieNames from 'constants/cookieNames';

import {
  MainDynamicNavigation,
  mainNavComponentsMapping,
  getLinkHrefsMetaData,
} from '../../../_ui/_navigation/MainDynamicNavigation';

import { DividerIcon } from './_components/DividerIcon';

type Props = {
  loginUrl: string;
};

const MainNavigation = ({ loginUrl }: Props): React.JSX.Element => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const { router } = useContext(RouterContext);
  const user = useUser();
  const { getTranslated, direction } = useContext(InternationalizationContext);

  const dispatch = useDispatch();

  const posthog = usePostHog();

  const getRootPathOrHome = user
    ? user.currentCompany
      ? `${getCompanyPath(user.currentCompany)}/home`
      : '/my-account'
    : '/';

  const renderUserDropDown = (): React.JSX.Element | null => {
    if (user) {
      return (
        <UserDropdown
          user={user}
          onLogoutClick={() => {
            if (posthog.__loaded) {
              // https://posthog.com/docs/libraries/js#reset-after-logout
              posthog.reset(true);
            }
            router.push('/auth/logout');
          }}
          onCompanyClick={(id) => {
            const nextCurrentCompany = user.companies.find((company) => company.aclCompanyId === id);
            Cookies.set(CookieNames.currentCompanyId, id);
            // Update the 'getUser' query data in Redux manually to reflect the change in the current company.
            dispatch(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              api.util.updateQueryData('getUser', undefined, (user) => {
                user.currentCompany = nextCurrentCompany;
              }),
            );
          }}
        />
      );
    }
    return null;
  };

  const handleEventLogIn = (): void => {
    window.analytics?.track('primary_nav--login_button--clicked');
  };

  const handleEventSignUp = (): void => {
    window.analytics?.track('primary_nav--signup_button--clicked');
  };

  const renderLoginSignup = (): React.JSX.Element => (
    <>
      <NavLink href={loginUrl} isExternal className="flex justify-center items-center w-full xl:w-auto">
        <span data-testid="link_login" className="text-base font-medium leading-6 truncate" onClick={handleEventLogIn}>
          {t('log in')}
        </span>
      </NavLink>
      <div className="xl:w-auto">
        <LinkButton
          size="full"
          theme="dark"
          href="/signup"
          onClick={handleEventSignUp}
          data-testid="main_navigation--sign_up_button"
        >
          <span className="truncate" data-testid="sign_up_button">
            {t('sign up')}
          </span>
        </LinkButton>
      </div>
    </>
  );

  const renderMobileLogoOrActiveMainNavLink = (): React.JSX.Element => {
    const MobileLogo = (
      <Link
        className={classnames('flex justify-center items-center py-2', {
          'pl-0.5': !user,
          'pl-4': user,
        })}
        href={getRootPathOrHome}
        data-testid="get_url_path_link"
      >
        <Image
          src={dunsguideLogo}
          alt={getTranslated({
            en: 'Dunsguide logo',
            es: 'Logotipo de Dunsguide',
            br: 'Logotipo do Dunsguide',
          })}
          height={38}
          width={145}
          priority
          layout="intrinsic"
          style={{
            width: '145px',
            minWidth: '145px',
            maxWidth: '100%',
            height: '38px',
            minHeight: '38px',
            objectFit: 'contain',
            objectPosition: replaceDirection('left', direction),
          }}
        />
      </Link>
    );

    if (!user) {
      return MobileLogo;
    }

    const { pathname, asPath } = router;
    const MainNavComponentsMap = mainNavComponentsMapping({
      t,
      currentCompany: user.currentCompany,
    });

    if (user.currentCompany) {
      if (pathname === '/companies') {
        const NavComponent = MainNavComponentsMap[MainNavLink.DISCOVER];
        return <NavComponent href={getLinkHrefsMetaData(MainNavLink.DISCOVER, user.currentCompany).default} />;
      }
      if (asPath.includes('admin/pulso')) {
        const NavComponent = MainNavComponentsMap[MainNavLink.PULSO];
        return <NavComponent href={getLinkHrefsMetaData(MainNavLink.PULSO, user.currentCompany).default} />;
      }
      if (asPath.includes('admin/home')) {
        const NavComponent = MainNavComponentsMap[MainNavLink.HOME_COMPANY];
        return <NavComponent href={getLinkHrefsMetaData(MainNavLink.HOME_COMPANY, user.currentCompany).default} />;
      }
      // TODO restructure admin pages
      if (
        asPath.includes('admin/profile') ||
        asPath.includes('admin/reviews') ||
        asPath.includes('admin/analytics') ||
        asPath.includes('admin/settings')
      ) {
        const NavComponent = MainNavComponentsMap[MainNavLink.PROFILE];
        return <NavComponent href={getLinkHrefsMetaData(MainNavLink.PROFILE, user.currentCompany).default} />;
      }
    } else {
      if (pathname === '/companies') {
        const NavComponent = MainNavComponentsMap[MainNavLink.DISCOVER];
        return <NavComponent href={getLinkHrefsMetaData(MainNavLink.DISCOVER).default} />;
      }
      if (pathname.includes('/my-account')) {
        const NavComponent = MainNavComponentsMap[MainNavLink.HOME_USER];
        return <NavComponent href={getLinkHrefsMetaData(MainNavLink.HOME_USER).default} />;
      }
    }

    return MobileLogo;
  };

  return (
    <Navbar
      variant="dark"
      expand="xl"
      expanded={expanded}
      onToggle={setExpanded}
      className={classnames('flex gap-x-4 justify-between items-center h-[70px]', {
        '-ml-4 xl:ml-0 min-[1320px]:pl-0': user,
      })}
    >
      {/* desktop - up to xl (1280px) */}
      <Link
        className="hidden xl:flex justify-center items-center py-2 pl-0.5"
        href={getRootPathOrHome}
        data-testid="get_url_path_link"
      >
        <Image
          src={dunsguideLogo}
          alt={getTranslated({
            en: 'Dunsguide logo',
            es: 'Logotipo de Dunsguide',
            br: 'Logotipo do Dunsguide',
          })}
          height={38}
          width={145}
          priority
          style={{
            width: '145px',
            minWidth: '145px',
            maxWidth: '100%',
            height: '38px',
            minHeight: '38px',
            objectFit: 'contain',
            objectPosition: replaceDirection('left', direction),
          }}
        />
      </Link>

      {/* mobile - until xl (1280px) */}
      <div className="flex xl:hidden">{renderMobileLogoOrActiveMainNavLink()}</div>

      {/* mobile - until xl (1280px) */}
      {user ? (
        <div className="flex xl:hidden gap-x-4 items-center">
          <ChangeLanguageButton variant="text-light" />
          {renderUserDropDown()}
        </div>
      ) : (
        <Navbar.Toggle aria-controls="responsive-navbar-nav" data-testid="navbar_toggle">
          {expanded ? <XIcon className="w-6 h-6" /> : <MenuIcon className="w-6 h-6" />}
        </Navbar.Toggle>
      )}

      <Navbar.Collapse id="responsive-navbar-nav" className="justify-start">
        <Nav className="flex flex-col xl:flex-row gap-x-4 justify-between w-full">
          <div className="flex flex-col xl:flex-row gap-x-3 gap-y-3">
            {/* mobile - until xl (1280px) */}
            <div data-testid="searchbar" className="pt-4 pb-4 m-0 w-full d-block d-xl-none">
              <SearchBar small />
            </div>
            <MainDynamicNavigation user={user} styles={user ? undefined : { containerPadding: 'p-0' }} />
          </div>

          <div className="flex flex-col xl:flex-row gap-x-3 gap-y-3 items-center">
            {/* desktop - up to xl (1280px) */}
            <div data-testid="searchbar" className="m-0 d-none d-xl-block max-w-[200px]">
              <SearchBar small />
            </div>

            <div className="flex items-center self-start xl:self-center mt-4 xl:mt-0 h-10">
              <div className={classnames('py-2', { 'px-4': user, 'px-0 xl:px-2': !user })}>
                <ChangeLanguageButton variant="text-light" />
              </div>
              <DividerIcon className="hidden xl:block" />
            </div>

            {user ? renderUserDropDown() : renderLoginSignup()}
          </div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export { MainNavigation };
